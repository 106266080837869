.spinner_container {
  position: relative;
  margin: auto;
}

.spinner_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  span {
    color: rgba(92, 112, 128, 0.6);
  }
}
