@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/table/lib/css/table.css';
@import '~@blueprintjs/core/lib/scss/variables';
@import '@fontsource/ubuntu';

//Brand Colors
$Google: #de5246;
$Amazon: #ff9900;
$Instagram: #bc2a8d;

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 1.5em;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  max-width: 100%;
}

// override font weight since Ubuntu headers are good light
.bp3-heading {
  font-weight: 300;
  .accent {
    font-weight: 700;
  }
}


.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.inputForm {
  max-width: 800px;
  padding: 0 40px;
}
.body {
  max-width: inherit;
  padding: 10px 0;
  .fileInput::hover {
    cursor: pointer;
  }
  .btn {
    margin-top: 1em;
  }
  .cardArea {
    display: flex;
    max-width: 100%;
    .col {
      display: inherit;
      width: 100%;
      margin: 0px 10px;
    }
  }
  .resultDescription {
    font-size: 2rem;
  }
  .resultDescriptionSmall {
    font-size: 1.5rem;
  }
}

.tutorialVideo {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 720px;
}
