@import '~@blueprintjs/core/lib/scss/variables';

$card-margin: 7px;
$image-max-width: 500px;
$card-padding: 20px;

.resultCard {
  max-width: MIN(calc(100vw - #{2 * $card-margin}), #{$image-max-width + 2 * $card-padding});
  text-align: start;
  margin: $card-margin;
  padding: $card-padding;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .resultCardImgContainer {
    max-width: MIN(calc(100vw - #{2 * ($card-padding + $card-margin)}), $image-max-width);
  }
  .resultCardImg {
    max-width: inherit;
    object-fit: contain;
  }
  .resultCardDescription {
    font-size: 1rem;
  }
  .resultCArdImageDownloadButton {
    align-self: flex-end;
  }
}

.resultPageContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-content: flex-start;
}
